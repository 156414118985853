
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.privilege {
  &__actions {
    display: flex;
    align-items: center;
    gap: $base-gutter;
  }
  &__pagination {
    justify-content: center;
    margin: $base-gutter 0;
  }
}
